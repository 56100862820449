/* eslint-disable max-len */
/* -------------------------------------------------------------------------- */
/*                                partenariats Fake Data                             */
/* -------------------------------------------------------------------------- */
const Partnerships = {
  sectionOne: {
    Paragraph: `"Les partenariats européens rassemblent la Commission européenne et des partenaires privés et/ou publics pour relever des défis supplémentaires par le biais d'initiatives concertées de recherche et d'innovation, en plus des défis relevés par Horizon Europe. Ils constituent un outil de mise en œuvre essentiel d'Horizon Europe, et contribuent de manière significative à la réalisation des priorités politiques de l'UE.
En réunissant des partenaires privés et publics, les partenariats européens permettent d'éviter la duplication des investissements et contribuent à réduire la fragmentation du paysage de la recherche et de l'innovation dans l'UE.
La Commission a réalisé des analyses d'impact qui ont permis d'identifier les candidats aux partenariats. Le portefeuille des partenariats européens comprend 49 candidats qui ont maintenant été pris en compte dans la prochaine étape des préparatifs.
Les candidats aux partenariats sont répartis dans cinq domaines.
`,
    ListOne: [
      {
        id: 1,

        title: 'Santé ',
      },
      {
        id: 2,

        title: 'Numérique, industrie et espace  ',
      },
      {
        id: 3,

        title: 'Climat, énergie et mobilité',
      },
      {
        id: 4,

        title:
          'Alimentation, bioéconomie, ressources naturelles, agriculture et environnement',
      },
      {
        id: 5,

        title:
          'Partenariats transversaux : EIT KICs, Innovative SMEs (anciennement « EUROSTARS » ; European Open Science Cloud Partnership.',
      },
    ],
  },
  sectionTwo: {
    ListTwo: [
      {
        id: 1,
        title: ' Types de partenaires :',
        paragraph: 'Il en existe trois types',
      },
      {
        id: 2,
        title: ' Les partenariats européens Co programmés : ',
        paragraph:
          "Il s'agit de partenariats entre la Commission et des partenaires privés et/ou publics. Ils sont basés sur des protocoles d'accord et/ou des arrangements contractuels.",
      },
      {
        id: 3,
        title:
          "Partenariats européens cofinancés au moyen d'une action cofinancée par le programme:",
        paragraph:
          "Des partenariats impliquant les pays de l'UE, avec des bailleurs de fonds de la recherche et d'autres autorités publiques au cœur du consortium.",
      },
      {
        id: 4,
        title: 'Partenariats européens institutionnalisés :',
        paragraph:
          "Il s'agit de partenariats dans le cadre desquels l'UE participe à des programmes de financement de la recherche et de l'innovation entrepris par les pays de l'UE.",
      },
    ],
    Conclusion:
      "Ces partenariats nécessitent des propositions législatives de la part de la Commission et sont basés sur un règlement du Conseil (article 187) ou une décision du Parlement européen et du Conseil (article 185). Ils sont mis en œuvre par des structures spécialisées créées à cette fin. Les partenariats institutionnalisés ne seront mis en œuvre que lorsque d'autres parties du programme Horizon Europe, y compris d'autres types de partenariat, ne permettraient pas d'atteindre les objectifs souhaités ou d'obtenir les effets escomptés.",
  },
};
export default Partnerships;
