/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Package
import React from 'react';
// Proptypes
import PropTypes from 'prop-types';
// Local UI components
// Style
import '../index.scss';

/* -------------------------------------------------------------------------- */
/*                                 PageContent                                */
/* -------------------------------------------------------------------------- */
function Body({ Data }) {
  const { ListTwo, Conclusion } = Data;
  return (
    <>
      <div className="section">
        {ListTwo.map((item) => {
          const { id, title, paragraph } = item;
          return (
            <div>
              <h5 key={id}>{title}</h5>
              <p>{paragraph}</p>
            </div>
          );
        })}
        <p className="conclusion">{Conclusion}</p>
      </div>
    </>
  );
}

export default Body;
Body.propTypes = {
  Data: PropTypes.objectOf(PropTypes.object).isRequired,
};
