/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import React from 'react';
// PropTypes
import PropTypes from 'prop-types';
// Local UI components
import Intro from './page-content/Intro';
import Body from './page-content/Body';
// Style
import './index.scss';
/* -------------------------------------------------------------------------- */
/*                                    Page Content                            */
/* -------------------------------------------------------------------------- */

function PageContent({ pageContent }) {
  const { sectionOne, sectionTwo } = pageContent;
  return (
    <>
      <Intro Data={sectionOne} />
      <Body Data={sectionTwo} />
    </>
  );
}
export default PageContent;
PageContent.propTypes = {
  pageContent: PropTypes.objectOf(PropTypes.object).isRequired,
};
